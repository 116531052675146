import React, { Children } from 'react';
import ReactDOM from 'react-dom';
import { getModel } from './state';
import { removeEl, root } from '../utils';
import WidgetNode from '../components/widget-node';

const modelNodes = {};

export function hydrateContent(el: Element): void {
  const widgets = el.getElementsByTagName('vev');
  let i = widgets.length;
  while (i--) hydrate(widgets.item(i) as Element);
}

function hydrate(el: Element) {
  const hydrateEl = el.children.item(0);

  // IF no element or el is child list thhen return (nothing to hydrate)
  if (!hydrateEl || !hydrateEl.classList.contains('__wc')) return;
  const id = el.id as string;
  const model = getModel(id);

  if (!model || !model.type) return;

  // MAYBE PRELOAD? ? System.import??
  try {
    modelNodes[id] = hydrateEl;
    const contentHTML = hydrateEl.children.length
      ? hydrateEl.children.item(0).innerHTML
      : undefined;
    const hostRef: React.RefObject<HTMLDivElement> = { current: el as HTMLDivElement };
    ReactDOM.render(<WidgetNode model={model} html={contentHTML} hostRef={hostRef} />, hydrateEl);
  } catch (e) {
    console.error('Failed to render', id, model, e);
  }
}

export function unmountContent(tag: Element): void {
  unmountRecursive(tag);
  removeEl(tag);
}

function unmountRecursive(tag: Element | null) {
  if (!tag) return;
  const id = tag.id;
  if (id && id in modelNodes) {
    ReactDOM.unmountComponentAtNode(modelNodes[id]);
    delete modelNodes[id];
    unmountRecursive(tag.querySelector('.__c'));
  } else {
    for (let i = 0; i < tag.children.length; i++) unmountRecursive(tag.children.item(i));
  }
}

export function replaceFixedContent(newElements: HTMLCollectionOf<Element> | Element[]) {
  // Convert HTML collections to arrays (so elements don't disappear from the list when moved around)
  if ('item' in newElements) newElements = [].slice.call(newElements);
  const currentEls = [].slice.call(root.getElementsByClassName('__f'));

  let existingFixed: Element[] = new Array(newElements.length);
  let i = currentEls.length;
  while (i--) {
    let el = currentEls[i];
    let j = newElements.length;
    while (j--) {
      if (el.id === newElements[j].id) {
        existingFixed[j] = el;
        break;
      }
    }
    // index < 0 then fixed does not exist on page and need to be removed
    if (j < 0) unmountContent(el);
  }

  // Loop over fixed elemnets and insert them at the right position
  for (let i = 0; i < newElements.length; i++) {
    if (!existingFixed[i]) hydrateContent(newElements[i]);
    root.appendChild(existingFixed[i] || newElements[i]);
  }
}

import { doc, isBrowser } from '../utils';
import { hydrateContent } from './render';

const resolvedPages: { [pageKey: string]: Promise<string> } = {};

async function fetchHtml(pageKey: string, path: string): Promise<string> {
  if (!isBrowser) return '';
  const res = await fetch(path);
  const text = await res.text();
  const m = text.match(/<body>((.|\n)*)<\/body>/m);
  return (m && m[0]) || '';
}

export function hasResolved(pageKey: string) {
  return pageKey in resolvedPages;
}

export function preloadPageHTML(pageKey: string, absolutePath: string): Promise<string> {
  if (pageKey in resolvedPages) return resolvedPages[pageKey];
  return (resolvedPages[pageKey] = fetchHtml(pageKey, absolutePath));
}

export async function resolvePage(
  pageKey: string,
  absolutePath: string
): Promise<[HTMLElement, HTMLCollectionOf<Element>]> {
  const newPageTag = doc.createElement('html');
  newPageTag.innerHTML = await preloadPageHTML(pageKey, absolutePath);

  hydrateContent(newPageTag);
  const pageTag = newPageTag.querySelector('.__p');
  const fixed = newPageTag.getElementsByClassName('__f');
  if (!pageTag) throw Error('Page not found: ' + pageKey);

  return [<HTMLElement>pageTag, fixed];
}

import React from 'react';
import { useWidget, useModel, ModelProvider } from '../core/hooks';
import ErrorBounds from './error-bounds';

interface AddonProps {
  id: string;
  children?: React.ReactElement;
  hostRef: React.RefObject<HTMLDivElement>;
}

interface AddonsProps {
  addons?: string[];
  hostRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement;
}
export const Addon: React.FC<AddonProps> = ({ id, children, hostRef }) => {
  const model = useModel(id);
  const Widget = useWidget(model && (model.type as string));

  if (!model || !model.type || !Widget) return children;

  return (
    <ModelProvider value={model}>
      <Widget {...model.content} className={'__a ' + model.cl} id={model.key} hostRef={hostRef}>
        {children}
      </Widget>
    </ModelProvider>
  );
};

export const Addons: React.FC<AddonsProps> = ({ addons, hostRef, children }) => {
  if (!addons || !addons.length) return children;

  return (
    <Addon key={addons[0]} id={addons[0]} hostRef={hostRef}>
      <Addons addons={addons.slice(1)} hostRef={hostRef}>
        {children}
      </Addons>
    </Addon>
  );
};

import React from 'react';
import { IContent } from 'vev';
import { useWidget, ModelProvider } from '../core/hooks';
import ErrorBounds from './error-bounds';
import { Addons } from './addon';

interface WidgetNodeProps {
  model: IContent;
  container?: HTMLDivElement;
  children?: React.ReactNode;
  html?: string;
  hostRef: React.RefObject<HTMLDivElement>;
  className?: string;
}

const WidgetNode: React.FC<WidgetNodeProps> = ({ hostRef, model, className, html }) => {
  const Widget = useWidget(model.type as string);
  // const hostRef = React.useRef<HTMLDivElement>(null);

  let id = model.key + 'c';
  let cl = model.cl || '';
  if (className) cl += ' ' + className;

  return (
    <ErrorBounds>
      <Addons addons={model.actions} hostRef={hostRef}>
        {!html && Widget ? (
          <ModelProvider value={model}>
            <w id={id} className={cl}>
              <Widget {...model.content} hostRef={hostRef} />
            </w>
          </ModelProvider>
        ) : (
          <w id={id} className={cl} dangerouslySetInnerHTML={{ __html: html || '' }} />
        )}
      </Addons>
    </ErrorBounds>
  );
};

export default WidgetNode;

import { VevContent } from 'vev';
import { Router, Tracking, View } from '../manager';
import AppState, { store } from './state';
import { hydrateContent } from './render';
import { onLiveEvent } from '../utils';
import Timeline from '../model/timeline';
import system from '../system';
export { render, AppState, Timeline, store };

async function render(content: VevContent) {
  const polyfills:Promise<any>[] = []
  // Polyfill for fetch
  if(!('fetch' in window)) polyfills.push(system.fetch('https://cdn.jsdelivr.net/npm/whatwg-fetch@3.0.0/dist/fetch.umd.js'));
  // Polyfill for intersection observer
  if(!('IntersectionObserver' in window)) polyfills.push(system.import('https://unpkg.com/intersection-observer@0.5.1/intersection-observer'));
  await Promise.all(polyfills);


  store('content', content);
  Router.init(true);
  View.enable();
  // AppState.content = content;
  hydrateContent(document.body);
}

export * from './hooks';
export * from '../manager';
export * from '../components';
export * from '../utils/type';
export * from '../utils/dom'

onLiveEvent('a', 'click', (e, target) => {
  const path = target.getAttribute('href') || '';
  if (!path) return;

  const isInternal = /(#|\/)/.test(path.charAt(0));
  // If not internal link then return

  const isEmail = /^mailto/i.test(path);
  const isPhone = /^tel/i.test(path);
  let eventCategory;
  if (isInternal && !(e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    let tween = target.dataset.tween;
    if (tween) {
      try {
        tween = JSON.parse(tween);
      } catch (e) {
        console.error('Failed to parse tween', e);
      }
    }

    Router.go(path, tween);
    eventCategory = 'Internal';
  } else if (isEmail) eventCategory = 'Email';
  else if (isPhone) eventCategory = 'Phone';
  else eventCategory = 'Outbound';

  Tracking.send('link', eventCategory + ' Link', 'click', path);
});

import { VevContent, IContent, IEditorState, IAppState } from 'vev';
import { isFunction, root } from '../utils';

const AppState: IAppState = {
  scrollTop: 0,
  device: 'desktop',
  viewport: { width: root.clientWidth, height: root.clientHeight, scrollHeight: root.scrollHeight },
  content: {
    fixed: {},
    pages: [],
    models: [],
    ico: {},
    img: {},
    menus: {}
  },
  editorState: {},
  widgets: {}
};

export function getModel(modelKey: string): IContent | undefined {
  const content = AppState.content;
  for (let model of content.models) if (model.key === modelKey) return model;

  return undefined;
}

export function getModelChildren(modelKey: string): string[] {
  const model = getModel(modelKey);
  return (model && model.children) || [];
}

const listeners: { [event: string]: ((payload: any) => any)[] } = {};

function store<T extends keyof IAppState>(
  watchState: T,
  cb: (payload: IAppState[T]) => void,
  options?: any
): () => void;
function store<T extends keyof IAppState>(emitState: T, payload: any): void;
function store<T extends keyof IAppState>(
  event: T,
  cbOrPayload: any,
  options?: any
): void | (() => void) {
  const list = listeners[event] || (listeners[event] = []);
  if (isFunction(cbOrPayload)) {
    list.push(cbOrPayload);
    return () => {
      const index = list.indexOf(cbOrPayload);
      if (index !== -1) list.splice(index, 1);
    };
  } else {
    AppState[event] = cbOrPayload;
    for (let cb of list) cb(cbOrPayload);
  }
}
export { store };
export default AppState;

/* tslint:disable:no-console */
import { GestureProps, IGestureStatus, MultiFingerStatus } from 'vev';
import React from 'react';
import {
  calcRotation,
  getEventName,
  calcMultiFingerStatus,
  calcMoveStatus,
  shouldTriggerSwipe,
  shouldTriggerDirection,
  getMovingDirection,
  getDirectionEventName
} from './util';
import { PRESS, DIRECTION_ALL, DIRECTION_VERTICAL, DIRECTION_HORIZONTAL } from './config';
import { Mouse, View } from '../../manager';

const directionMap = {
  all: DIRECTION_ALL,
  vertical: DIRECTION_VERTICAL,
  horizontal: DIRECTION_HORIZONTAL
};

export default class Gesture extends React.Component<GestureProps, any> {
  state = {};

  private gesture?: IGestureStatus;

  private event?: MouseEvent | TouchEvent;

  private pressTimer?: NodeJS.Timer;

  private directionSetting: number;

  events: any;

  get hasPinch(): boolean {
    return Boolean(
      this.props.onPinch || this.props.onPinchMove || this.props.onPinchIn || this.props.onPinchOut
    );
  }

  get hasRotate(): boolean {
    return Boolean(this.props.onRotate || this.props.onRotateMove);
  }

  constructor(props) {
    super(props);
    this.directionSetting = directionMap[props.direction || 'all'];

    this.events = {
      onTouchStart: this.start,
      onTouchMove: this.move,
      onTouchCancel: this.cancel,
      onTouchEnd: this.end
    };

    if (!View.isIOS) this.events.onMouseDown = this.mouseDown;
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  private emit = (name: string, ...args: any[]) => {
    const cb = this.props[name];
    if (typeof cb === 'function') {
      const { gesture } = this;
      // always give user gesture object as first params first
      cb(gesture ? { ...gesture } : gesture, ...args);
    }
  };

  triggerCombineEvent = (mainEventName, eventStatus, ...args) => {
    this.emit(mainEventName, ...args);
    this.triggerSubEvent(mainEventName, eventStatus, ...args);
  };

  triggerSubEvent = (mainEventName, eventStatus, ...args) => {
    if (eventStatus) {
      const subEventName = getEventName(mainEventName, eventStatus);
      this.emit(subEventName, ...args);
    }
  };

  triggerPinchEvent = (mainEventName, eventStatus, ...args) => {
    const { scale } = this.gesture as IGestureStatus;
    if (eventStatus === 'move' && typeof scale === 'number') {
      if (scale > 1) {
        this.emit('onPinchOut');
      }
      if (scale < 1) {
        this.emit('onPinchIn');
      }
    }
    this.triggerCombineEvent(mainEventName, eventStatus, ...args);
  };

  private initTimer = () => {
    this.clearTimer();
    this.pressTimer = setTimeout(() => {
      this.setGestureState({ press: true });
      this.emit('onPress');
    }, PRESS.time);
  };

  private clearTimer = () => clearTimeout(this.pressTimer as any);

  private setGestureState(params: Partial<IGestureStatus>) {
    const gesture = this.gesture || (this.gesture = {} as any);
    // cache the previous touches
    if (gesture.touches) gesture.preTouches = gesture.touches;
    Object.assign(gesture, { ...params });
  }

  private cleanGestureState = () => {
    delete this.gesture;
  };

  private getTouches(e: TouchEvent | MouseEvent): { x: number; y: number }[] {
    if ('touches' in e) {
      return Array.prototype.slice.call(e.touches).map(item => ({
        x: item.screenX,
        y: item.screenY
      }));
    }

    return [{ x: e.pageX, y: e.pageY }];
  }
  private triggerUserCb = (status, e) => {
    const cbName = getEventName('onTouch', status);
    if (cbName in this.props) {
      this.props[cbName](e);
    }
  };

  private mouseDown = (e: MouseEvent) => {
    console.log('MOUSE DOWN');
    this.start(e);
    Mouse.on('mousemove', this.move);
    Mouse.on('mouseup', this.mouseUp);
  };

  private mouseUp = (e: MouseEvent) => {
    Mouse.off('mousemove', this.move);
    Mouse.off('mouseup', this.mouseUp);
    this.end(e);
  };

  private start = (e: TouchEvent | MouseEvent) => {
    this.triggerUserCb('start', e);
    this.event = e;
    if ('touches' in e && e.touches.length > 1) e.preventDefault();

    this.initStatus(e);
    this.initTimer();
    this.checkIfMultiTouchStart();
  };

  private initStatus = (e: TouchEvent | MouseEvent) => {
    this.cleanGestureState();
    // store the gesture start state
    const startTouches = this.getTouches(e);
    const startTime = Date.now();
    const startMultiFingerStatus = calcMultiFingerStatus(startTouches);
    console.log('TOUCH DOWN', 'touches' in e && e.touches.length);

    this.setGestureState({
      startTime,
      startTouches,
      startMultiFingerStatus,
      /* copy for next time touch move cala convenient*/
      time: startTime,
      touches: startTouches,
      multiFingerStatus: startMultiFingerStatus,
      srcEvent: this.event
    });
  };

  private checkIfMultiTouchStart = () => {
    const { hasPinch, hasRotate } = this;
    const { touches } = this.gesture as IGestureStatus;
    if (touches.length > 1 && (hasPinch || hasRotate)) {
      if (hasPinch) {
        const startMultiFingerStatus = calcMultiFingerStatus(touches);
        this.setGestureState({
          startMultiFingerStatus,

          /* init pinch status */
          pinch: true,
          scale: 1
        });
        this.triggerCombineEvent('onPinch', 'start');
      }
      if (hasRotate) {
        this.setGestureState({
          /* init rotate status */
          rotate: true,
          rotation: 0
        });
        this.triggerCombineEvent('onRotate', 'start');
      }
    }
  };

  private move = (e: TouchEvent | MouseEvent) => {
    console.log('TOUCH MOVE', 'touches' in e && e.touches.length);

    this.triggerUserCb('move', e);
    this.event = e;
    // sometimes weird happen: touchstart -> touchmove..touchmove.. --> touchend --> touchmove --> touchend
    // so we need to skip the unnormal event cycle after touchend
    if (this.gesture) {
      // not a long press
      this.clearTimer();

      this.updateGestureStatus(e);
      this.checkIfSingleTouchMove();
      this.checkIfMultiTouchMove();
    }
  };

  private checkIfMultiTouchMove = () => {
    const { pinch, rotate, touches, startMultiFingerStatus, multiFingerStatus } = this
      .gesture as IGestureStatus;
    if (!pinch && !rotate) return;

    if (touches.length < 2) {
      this.setGestureState({
        pinch: false,
        rotate: false
      });
      // Todo: 2 finger -> 1 finger, wait to test this situation
      pinch && this.triggerCombineEvent('onPinch', 'cancel');
      rotate && this.triggerCombineEvent('onRotate', 'cancel');
    } else if (multiFingerStatus && startMultiFingerStatus) {
      if (pinch) {
        const scale = multiFingerStatus.z / startMultiFingerStatus.z;
        this.setGestureState({ scale });
        this.triggerPinchEvent('onPinch', 'move');
      }
      if (rotate) {
        const rotation = calcRotation(startMultiFingerStatus, multiFingerStatus);
        this.setGestureState({
          rotation
        });
        this.triggerCombineEvent('onRotate', 'move');
      }
    }
  };

  allowGesture() {
    return this.gesture && shouldTriggerDirection(this.gesture.direction, this.directionSetting);
  }

  checkIfSingleTouchMove = () => {
    const { pan, touches, moveStatus, preTouches, availablePan = true } = this
      .gesture as IGestureStatus;
    if (touches.length > 1) {
      this.setGestureState({ pan: false });
      // Todo: 1 finger -> 2 finger, wait to test this situation
      pan && this.triggerCombineEvent('onPan', 'cancel');
      return;
    }

    // add avilablePan condition to fix the case in scrolling, which will cause unavailable pan move.
    if (moveStatus && availablePan) {
      const direction = getMovingDirection(preTouches[0], touches[0]);
      this.setGestureState({ direction });

      const eventName = getDirectionEventName(direction);
      if (!this.allowGesture()) {
        // if the first move is unavailable, then judge all of remaining touch movings are also invalid.
        if (!pan) {
          this.setGestureState({ availablePan: false });
        }
        return;
      }
      if (!pan) {
        this.triggerCombineEvent('onPan', 'start');
        this.setGestureState({
          pan: true,
          availablePan: true
        });
      } else {
        this.triggerCombineEvent('onPan', eventName);
        this.triggerSubEvent('onPan', 'move');
      }
    }
  };

  checkIfMultiTouchEnd = status => {
    const { pinch, rotate } = this.gesture as IGestureStatus;
    if (pinch) {
      this.triggerCombineEvent('onPinch', status);
    }
    if (rotate) {
      this.triggerCombineEvent('onRotate', status);
    }
  };

  updateGestureStatus = (e: TouchEvent | MouseEvent, touches = this.getTouches(e)) => {
    const time = Date.now();
    this.setGestureState({ time });

    const { startTime, startTouches, pinch, rotate, startMultiFingerStatus } = this
      .gesture as IGestureStatus;

    const moveStatus = calcMoveStatus(startTouches, touches, time - startTime);
    let multiFingerStatus: MultiFingerStatus | undefined;
    if (pinch || rotate) {
      multiFingerStatus = calcMultiFingerStatus(touches);
    }

    this.setGestureState({
      startMultiFingerStatus: startMultiFingerStatus || multiFingerStatus,
      /* update status snapshot */
      touches,
      multiFingerStatus,
      /* update duration status */
      moveStatus
    });
  };
  private end = (e: TouchEvent | MouseEvent) => {
    this.triggerUserCb('end', e);
    this.event = e;
    if (!this.gesture) return;
    this.clearTimer();
    this.updateGestureStatus(e, this.gesture.touches);
    this.doSingleTouchEnd('end');
    this.checkIfMultiTouchEnd('end');
  };

  private cancel = e => {
    this.triggerUserCb('cancel', e);
    this.event = e;

    if (!this.gesture) return;

    this.clearTimer();
    this.updateGestureStatus(e);
    this.doSingleTouchEnd('cancel');
    this.checkIfMultiTouchEnd('cancel');
  };
  triggerAllowEvent = (type, status) => {
    if (this.allowGesture()) {
      this.triggerCombineEvent(type, status);
    } else {
      this.triggerSubEvent(type, status);
    }
  };
  doSingleTouchEnd = status => {
    const { moveStatus, pinch, rotate, press, pan, direction } = this.gesture as IGestureStatus;

    if (pinch || rotate) {
      return;
    }
    if (moveStatus) {
      const { z, velocity } = moveStatus;
      const swipe = shouldTriggerSwipe(z, velocity);
      this.setGestureState({
        swipe
      });
      if (pan) {
        // pan need end, it's a process
        // sometimes, start with pan left, but end with pan right....
        this.triggerAllowEvent('onPan', status);
      }
      if (swipe) {
        const directionEvName = getDirectionEventName(direction);
        // swipe just need a direction, it's a endpoint
        this.triggerAllowEvent('onSwipe', directionEvName);
        return;
      }
    }

    if (press) {
      this.emit('onPressUp');
      return;
    }
    this.emit('onTap');
  };

  render() {
    const { children } = this.props;
    const { directionSetting } = this;
    const child = React.Children.only(children);

    if (!React.isValidElement(child) || typeof child.type !== 'string') { return <div>Need 1 native html element as child</div>; }

    let touchAction: string = 'auto';
    if (this.hasPinch || this.hasRotate || directionSetting === DIRECTION_ALL) {
      touchAction = 'pan-x pan-y';
    } else if (directionSetting === DIRECTION_VERTICAL) {
      touchAction = 'pan-x';
    } else if (directionSetting === DIRECTION_HORIZONTAL) {
      touchAction = 'pan-y';
    }

    const style = (child.props as any).style;
    return React.cloneElement(child, {
      ...this.events,
      style: {
        touchAction,
        ...style
      }
    });
  }
}

import { DeviceMode, DeviceScaling } from 'vev';
import { doc, global, getById, root, isString, addClass } from '../utils';
import anime, { AnimeInstance } from 'animejs';
import { store } from '../core/state';

/** Scroll to animation  speed (px / s) */
const SCROLL_SPEED = 800;

const SIZES = {
  desktop: 1024,
  tablet: 600,
  mobile: 320
};

const scrollEl = doc.documentElement || doc.scrollingElement || root || {};
const docScrollTop = (): number => global.pageYOffset || (scrollEl as any).scrollTop;
const docScrollHeight = (): number => (scrollEl as any).scrollHeight;
const ua = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '';
const hasUa = (name: string): boolean => ua.indexOf(name) > -1;

let updateSizeTimeout: NodeJS.Timeout;

/**
 * global manager handles global related events like scrolling, resize and device mode
 * @event devicemode: (mode: string, global:globalManager)
 * @event resize: (global:globalManager)
 * @event zoom: (zoom: number, global:globalManager)
 * @event viewscroll
 */
class ViewManager {
  public width: number = global.innerWidth;
  public height: number = global.innerHeight;
  public mode: DeviceMode = 'desktop';
  public zoom: number = 1;
  public scaling: DeviceScaling = [];
  public scrollTop: number = docScrollTop();
  public scrollHeight: number = 0;
  public scrollDir: -1 | 1 = 1;

  public get percentY(): number {
    return this.scrollTop / (this.scrollHeight - this.height);
  }

  // must check msStream because IE added iPhone to ua to get ios g-mail
  public readonly isIOS: boolean = /ipad|iphone|ipod/i.test(ua) && !(<any>global).MSStream;
  public readonly isSafari: boolean = hasUa('safari/');
  public readonly isAndroid: boolean = hasUa('android');
  public readonly isFirefox: boolean = hasUa('firefox');
  public readonly isEdge: boolean = hasUa('edge');
  public readonly isIE: boolean = hasUa('msie ') || hasUa('trident/');
  public readonly isChrome: boolean = hasUa('chrome') && !this.isEdge;
  public readonly isOpera: boolean = typeof (<any>global).opr !== 'undefined';

  public get isMobile(): boolean {
    return this.mode === 'mobile';
  }

  public get isTablet(): boolean {
    return this.mode === 'tablet';
  }

  public get isDesktop(): boolean {
    return this.mode === 'desktop';
  }

  private el?: HTMLDivElement;
  private sheet?: CSSStyleSheet;
  private scrollEl: Element = scrollEl as any;
  private disabled: boolean = true;

  constructor() {
    if (doc.createElement) {
      this.el = doc.createElement('div');
      this.el.setAttribute(
        'style',
        'position:absolute;height:100vh;width:100vw;left:-10000px;top:-10000px;z-index:1000'
      );

      root.appendChild(this.el);

      const styleEl = doc.createElement('style');
      if (doc.head) doc.head.appendChild(styleEl);

      this.sheet = <CSSStyleSheet>styleEl.sheet;
      this.sheet.insertRule(
        '.__p, .__f{-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;text-size-adjust: 100%;}',
        0
      );
      this.sheet.insertRule('html{}', 1);
      this.setEl(global);

      // Start interval check of scroll height
      setInterval(this.checkScrollHeight, 250);
    }
  }

  public enable() {
    this.disabled = false;
    this.updateSize(true);
  }

  /**
   * Set scroll container element
   */
  public setEl(scrollEl: Window | Element) {
    // if (this.scrollEl) {
    //   global.removeEventListener('resize', this.handleResize);
    //   global.removeEventListener('scroll', this.handleScroll);
    // }

    // const doc = (<global>scrollEl).document;
    // if (doc) {
    //   // IE don't have scroll element
    //   this.scrollEl = doc.scrollingElement || doc.documentElement || doc.body;
    // } else {
    //   this.scrollEl = <Element>scrollEl;
    // }

    // const classList = this.scrollEl.classList;
    addClass(
      this.scrollEl,
      this.isIOS && 'ios',
      this.isAndroid && 'android',
      this.isIE && 'ie',
      this.isEdge && 'edge',
      this.isChrome && 'chrome',
      this.isFirefox && 'firefox',
      this.isOpera && 'opera',
      this.isSafari && 'safari'
    );
    // if (this.isIOS) classList.add('ios');
    // if (this.isAndroid) classList.add('android');
    // if (this.isIE) classList.add('ie');
    // if (this.isEdge) classList.add('edge');
    // if (this.isChrome) classList.add('chrome');
    // if (this.isFirefox) classList.add('firefox');
    // if (this.isOpera) classList.add('opera');
    // if (this.isSafari) classList.add('safari');

    this.scrollTop = this.scrollEl.scrollTop;
    this.updateSize();

    global.addEventListener('resize', this.handleResize, { passive: true });
    global.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  setScrollTop(value: number) {
    window.scrollTo(0, value);
  }

  setMode(mode: DeviceMode) {
    if (this.mode !== mode) {
      const classList = this.scrollEl.classList;
      if (this.mode) classList.remove(this.mode);
      classList.add(mode);
      this.mode = mode;
      store('device', mode);
    }
  }

  update() {
    this.updateSize();
  }

  getElCenteringPos(el: Element): number;
  getElCenteringPos(id: string, insideEl?: Element): number;
  getElCenteringPos(el: Element | string, insideEl?: Element): number {
    if (typeof el === 'string') el = <Element>getById(el, insideEl);
    if (!el) return 0;
    const { top, height } = el.getBoundingClientRect();
    return top - Math.max(0, this.height / 2 - height / 2);
  }

  scrollTo(top: number, noAnimation?: boolean, duration?: number): Promise<any>;
  scrollTo(elementId: string, noAnimation?: boolean, duration?: number): Promise<any>;
  scrollTo(val: string | number, noAnimation?: boolean, duration?: number): Promise<any> {
    if (isString(val)) val = this.scrollTop + this.getElCenteringPos(val);
    anime.remove('html, body');
    const dScroll = Math.abs(val - this.scrollTop);
    if (!duration && (noAnimation || dScroll > this.height * 2)) {
      this.setScrollTop(val);
      return Promise.resolve();
    } else {
      return anime({
        targets: 'html, body',
        scrollTop: [this.scrollTop, val],
        easing: 'easeOutQuart',
        duration: duration * 1000 || (dScroll / SCROLL_SPEED) * 1000
      }).finished;
    }
  }

  private checkScrollHeight = () => {
    const scrollHeight = docScrollHeight();
    if (scrollHeight !== this.scrollHeight) {
      console.log('update scroll');
      this.scrollHeight = scrollHeight;
      store('viewport', { width: this.width, height: this.height, scrollHeight });
    }
  };

  private updateSize = (force?: boolean) => {
    if (!this.el) return;
    const height = this.el.clientHeight;
    const width = this.el.clientWidth;
    const scrollHeight = docScrollHeight();
    clearTimeout(updateSizeTimeout);
    console.log('update size');
    // Done update i no change
    if (
      !force &&
      width === this.width &&
      height === this.height &&
      scrollHeight === this.scrollHeight
    )
      return;

    this.height = height;
    this.width = width;
    this.scrollHeight = scrollHeight;
    if (this.disabled) return;

    let mode: DeviceMode;
    if (width >= height) mode = 'desktop';
    else if (width >= SIZES.tablet) mode = 'tablet';
    else mode = 'mobile';
    this.setMode(mode);

    const size = SIZES[this.mode];
    this.zoom = Math.round((width / size) * 100) / 100;
    if ((!this.isFullWidth() && this.zoom > 1) || this.disabled) this.zoom = 1;

    // Added zoom style to sheet
    if (this.sheet) {
      const holderStyle = (<any>this.sheet.cssRules[0]).style;
      const outerStyle = (<any>this.sheet.cssRules[1]).style;

      const zoom = this.zoom === 1 ? null : this.zoom;
      const textSizeAdjust = this.zoom === 1 ? null : `${Math.round(this.zoom * 100)}%`;
      // Tmp disabled firefox zoom
      if (this.isFirefox) {
        // holderStyle.MozTransform = zoom ? `scale(${zoom})` : null;
        // holderStyle.width = zoom ? `${100 / zoom}vw` : null;
        // holderStyle.height = zoom ? `${100 / zoom}vh` : null;
        // outerStyle.overflow = zoom ? 'hidden' : null;
      } else {
        holderStyle.zoom = zoom;
      }

      // If ie zoom has a bug with the widh, so we need to set width on holder
      if (this.isIE && !this.isEdge) {
        holderStyle.width = zoom ? 100 / zoom + '%' : null;
      }

      if ('textSizeAdjust' in holderStyle) holderStyle.textSizeAdjust = textSizeAdjust;
      else if ('webkitTextSizeAdjust' in holderStyle)
        holderStyle.webkitTextSizeAdjust = textSizeAdjust;
    }

    // Run update size another time to be certain that the size is correct
    // This is mainly added because IOS does not trigger window resize when done animating webview in apps
    updateSizeTimeout = setTimeout(this.updateSize, 400);
  };

  private handleScroll = (e: UIEvent): void => {
    const scrollTop = docScrollTop();
    if (scrollTop !== this.scrollTop) {
      this.scrollDir = scrollTop < this.scrollTop ? -1 : 1;
      this.scrollTop = scrollTop;
      store('scrollTop', scrollTop);
    }
  };

  private handleResize = (e: UIEvent): void => {
    this.updateSize();
    store('viewport', { width: this.width, height: this.height, scrollHeight: this.scrollHeight });
  };

  private isFullWidth(): boolean {
    return this.scaling.indexOf(this.mode) > -1;
  }

  private emitViewport() {}
}

export default new ViewManager();

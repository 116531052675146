declare const dataLayer: any;

type TrackingLayer = (
  event: string,
  category?: string,
  action?: string,
  label?: string,
  value?: any
) => void;

class TrackingManager {
  layers: TrackingLayer[] = [];

  add(layer: TrackingLayer) {
    this.layers.push(layer);
  }

  send(event: string, category?: string, action?: string, label?: string, value?: any): void {
    for (let layer of this.layers) layer.apply(layer, arguments as any);
  }
}

const manager = new TrackingManager();
manager.add(
  (event: string, category?: string, action?: string, label?: string, value?: any): void => {
    if (typeof dataLayer === 'undefined') return;
    return dataLayer.push({
      event: event,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    });
  }
);

export default manager;

// import System from './system';
import React from 'react';
import { View, AppState, Router, useEditorState, useModel, store } from './core';
import System from './system';
declare const riot: any;
// declare const System: any;
declare const TimelineLite: any;
declare const EaseLookup: any;
declare const TweenLite: any;
const { Fragment, useState, useEffect, useRef } = React;

type TimelineLite = any;

System.register(
  'riot',
  [
    'https://cdnjs.cloudflare.com/ajax/libs/riot/3.13.2/riot.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.1/TweenMax.min.js'
  ],
  function(ex) {
    return {
      execute: function() {
        ex('default', function({ children, hostRef }) {
          const model = useModel();
          const tag = useRef<any>();
          useEffect(() => {
            if (model.type === 'riot') return;
            setTimeout(() => {
              try {
                hostRef.current.classList.add(model.type);
                tag.current = riot.mount(hostRef.current, model.type, model)[0];
                tag.current.__icons = model.icons || {};
              } catch (e) {
                console.error('Riot error', e);
              }
            }, 1);
          }, []);

          if (tag.current) {
            setTimeout(() => {
              try {
                tag.current.update({ opts: model.content, __icons: model.icons });
              } catch (e) {
                console.error('Riot error', e);
              }
            }, 1);
          }

          return children || null;
        });


        riot.tag(
          'icon',
          `<svg if="{type === 0}" riot-viewBox="0 0 {value[0]} {value[1]}"><path each="{path in paths}" riot-d="{path}" /></svg>
          <img if="{type === 1}" riot-src="{value}" />`,
          'icon svg,icon img{width:100%; height:100%; fill:currentColor} icon{display:inline-block;vertical-align:middle; width:1.2em;height:1.2em;}',
          function() {
            let self = this,
              widget,
              icon;
            self.on('mount', () => {
              let parent = self.parent;
              // finding parent widget tag
              let i = 10;
              while (parent && !parent.__icons && i--) parent = parent.parent;
              widget = parent;
              update();
            });

            self.parent.on('update', update);
            self.on('update', update);

            function update() {
              if (!widget) return;
              icon = widget.__icons[self.opts.key];
              if (icon && Array.isArray(icon)) icon = { type: 0, value: icon };
              if (icon && self.value !== icon.value) {
                self.type = icon.type;
                self.value = icon.value;

                if (icon.type === 0) self.paths = icon.value.slice(2);
                if (self.isMounted) self.update();
              }
            }
          }
        );

        

        const Viewer = {
          on(event, cb) {
            console.log('ON', event);
          },

          off() {
            console.log('OFF', event);
          },

          getActivePageKey() {
            return AppState.route.pageKey;
          },

          getPagePath(key) {
            return Router.pagePathByKey(key);
          },

          getScrollTop() {
            return View.scrollTop;
          },

          getScrollElement: () => {
            return document.scrollingElement;
          },
          setScrollTop: value => {
            View.scrollTo(value);
          },
          scrollToElement: el => {
            View.scrollTo(el);
          },
          scrollToTop: () => {
            View.scrollTo(0);
          },

          getMenu() {
            return AppState.content.menus[AppState.content.primaryMenu];
          },
          getWindowManager() {
            return View;
          },

          getDeviceMode() {
            return View.mode;
          }
        };

        const RESIZABLE_IMAGE = /googleusercontent.com\//;
        const IMG_SIZES = [500, 800, 1080, 1600, 2000, 2600];
        const VIEWER_EVENTS = {
          viewerScroll: 'scrollTop',
          viewport: 'viewport',
          devicemode: 'device',
          page: 'route',
          state: 'state'
        };
        const WidgetMixin = {
          init: function() {
            this.viewer = Viewer;
            this.__icons = this.opts.icons || {};
            this.mapContent(this.opts.content);
            this.on('update', () => this.mapContent(this.opts.content));
            this.host = this.root;
            let stateCb = state => {
              if (state.keys.indexOf(this.parent.key) !== -1) {
                this.trigger('state', state);
              } else if (state.prevKeys.indexOf(this.parent.key) !== -1) {
                this.trigger('state', { deselect: true });
              }
            };

            this.__subs = {
              state: [store('editorState', state => {})]
            };

            // this.viewer.on('state', stateCb);
            // this.__listeners = [['state', stateCb]];

            this.on('unmount', () => {
              for (let key in this.__subs) {
                for (let unsub of this.__subs()) unsub();
              }
              // for (var listener of this.__listeners) {
              //   this.viewer.off(listener[0], listener[1]);
              // }
            });
          },

          mapContent(content) {
            for (let key in content) {
              this.opts[key] = content[key];
            }
          },

          timeline(onStart, onComplete) {
            return new TimelineManager(onStart, onComplete);
          },

          isDev() {},

          getImgUrl(url, size) {
            if (size && RESIZABLE_IMAGE.test(url)) {
              if (typeof size === 'string') {
                size = (this.viewer.getViewport()[2] * (parseFloat(size) || 0)) / 100;
              }
              var i = IMG_SIZES.length;
              var closest = IMG_SIZES[i - 1];
              while (i--) {
                var imgSize = IMG_SIZES[i];
                if (imgSize - size > 0) {
                  closest = imgSize;
                }
              }

              return url + '=s' + closest;
            }

            return url;
          },

          listen(eventKey, callback) {
            const viewerEvent = VIEWER_EVENTS[eventKey];
            if (viewerEvent) {
              const list = this.__subs[viewerEvent] || (this.__subs[viewerEvent] = []);
              list.push(store(viewerEvent, callback));
            } else {
              this.host.addEventListener(eventKey, callback);
            }
          },

          unlisten(eventKey, callback) {
            const viewerEvent = VIEWER_EVENTS[eventKey];
            if (viewerEvent) {
              const list = this.__subs[viewerEvent] || (this.__subs[viewerEvent] = []);
              const index = list.indexOf(callback);
              if (index !== -1) {
                list[index]();
                list.splice(index, 1);
              }
              // store(eventKey, callback);
            } else {
              this.host.removeEventListener(eventKey, callback);
            }
          },

          setPage(pageKey, transition) {
            Router.go(pageKey);
          }
        };

        riot.mixin('widget', WidgetMixin);

        riot.mixin(
          'action',
          Object.assign({}, WidgetMixin, {
            init: function() {
              WidgetMixin.init.call(this);
              this.viewer = this.parent.viewer;
              this.host = this.parent.parent.root;
            }
          })
        );

        const DEFAULT_TIME = 1;
        const EASING_FUNCS = [
          'Power0.easeNone',

          'Power2.easeIn',
          'Power2.easeOut',
          'Power2.easeInOut',

          'Elastic.easeIn',
          'Elastic.easeOut',
          'Elastic.easeInOut',

          'Bounce.easeIn',
          'Bounce.easeOut',
          'Bounce.easeInOut',

          'Back.easeIn',
          'Back.easeOut',
          'Back.easeInOut'
        ];

        function getGsapEasing(index) {
          return EaseLookup.find(EASING_FUNCS[index]);
        }

        class TimelineManager {
          scale: Object = {};
          replace: { [key: string]: string } = {};
          format: { [key: string]: (value: any) => any } = {};
          timeline: TimelineLite;
          onStart: any;
          onComplete: any;

          constructor(onStart, onComplete, onReverseComplete?: any) {
            this.onStart = onStart;
            this.onComplete = onComplete;
            this.timeline = new TimelineLite({
              paused: true,
              onStart: onStart,
              onComplete: onComplete,
              onReverseComplete: onReverseComplete,
              autoRemoveChildren: false
            });

            for (var attr of [
              'progress',
              'totalTime',
              'play',
              'pause',
              'reverse',
              'isActive',
              'timeScale'
            ]) {
              this[attr] = this.timeline[attr].bind(this.timeline);
            }
          }

          addReplace(attr: string, replaceAttr: string) {
            this.replace[attr] = replaceAttr;
          }

          addFormat(attr: string, formatFunc: (value: any) => any) {
            this.format[attr] = formatFunc;
          }

          setScaling(scale: Object) {
            this.scale = scale;
          }

          clear(triggerComplete: boolean) {
            const tl = this.timeline;
            if (triggerComplete && tl.progress() < 1) {
              this.onComplete();
            }
            tl.kill();
            tl.clear();
          }

          createTween(
            target,
            absDuration,
            attr,
            fromValue,
            toValue,
            easing,
            startOffset,
            endOffset
          ) {
            startOffset *= absDuration;
            endOffset *= absDuration;
            if (this.format[attr]) {
              fromValue = this.format[attr](fromValue);
              toValue = this.format[attr](toValue);
            }
            if (this.replace[attr]) attr = this.replace[attr];
            return TweenLite.fromTo(
              target,
              endOffset - startOffset,
              {
                [attr]: fromValue
              },
              {
                [attr]: toValue,
                ease: getGsapEasing(easing)
              }
            ).delay(startOffset);
          }

          set(target, style) {
            TweenLite.set(target, style);
          }

          clearStyle(...targets: HTMLElement[]) {
            for (var target of targets) {
              TweenLite.set(target, { clearProps: 'all' });
            }
          }

          buildTweenToValues(target, tweens, toValues) {
            if (!tweens || !target) return;
            if (!(tweens instanceof Array)) tweens = [tweens];

            const { timeline, scale } = this;
            // Cloning to values to not screw them up when writing to the object
            toValues = Object.assign({}, toValues);
            // Calculate the total time for all the tweens, because we need to build the tween backwards becuase the end values is known
            var currentTime = tweens.reduce((value, tween) => value + (tween.time || 1), 0);

            var i = tweens.length;
            // Loop over the tweens backwards where the first tweens is set to the to values
            while (i--) {
              var tween = tweens[i];
              const time = tween.time || DEFAULT_TIME;
              currentTime -= time;
              for (var attr in tween) {
                if (attr === 'time') continue;

                let [value, easing, startOffset, endOffset] = tween[attr];
                if (scale[attr]) value *= scale[attr];

                timeline.add(
                  this.createTween(
                    target,
                    time,
                    attr,
                    value,
                    toValues[attr],
                    easing,
                    startOffset,
                    endOffset
                  ),
                  currentTime
                );
                toValues[attr] = value;
              }
            }
          }

          buildTweenFromValues(target, tweens, fromValues) {
            if (!tweens || !target) return;
            if (!(tweens instanceof Array)) tweens = [tweens];

            const { timeline, scale } = this;
            fromValues = Object.assign({}, fromValues);
            var currentTime = 0;
            for (let tween of tweens) {
              const time = tween.time || DEFAULT_TIME;
              for (let attr in tween) {
                if (attr === 'time') continue;

                let [value, easing, startOffset, endOffset] = tween[attr];
                if (scale[attr]) value *= scale[attr];

                timeline.add(
                  this.createTween(
                    target,
                    time,
                    attr,
                    fromValues[attr],
                    value,
                    easing,
                    startOffset,
                    endOffset
                  ),
                  currentTime
                );
                fromValues[attr] = value;
              }

              currentTime += time;
            }
          }
        }
      }
    };
  }
);

import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { MeasureProps } from 'vev';


export default function Measure({ children, onResize, ...rest }: MeasureProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (entries[0]) onResize(entries[0].contentRect as DOMRectReadOnly);
    });

    observer.observe(ref.current as HTMLElement);
    return () => observer.disconnect();
  }, []);

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
}
